import React from "react"
import { graphql } from "gatsby"
import { SliceZone } from "../components/SliceZone"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
//import { RichText } from 'prismic-reactjs'
import Layout from '../components/layout'

import { Link } from 'gatsby'
import { Pagination } from "../components/pagination"

const Post = ({ data }) => {

  if (!data) return null
  const post = data.prismicPost
   
  const image = getImage(post.data.cover)
  const tags = post.data.categories
  const categoriesPost = []

  for (let i = 0; i < tags.length; i++) {
    categoriesPost.push(tags[i].category.document.data.name)
  }
  
  //console.log(categoriesPost)


  return ( 
    <React.Fragment>
      <Layout pageTitle="work">
        <Link to='/work' style={{display: "flex", alignContent: "center", textDecoration: "none", position: "absolute", top: "65px"}}>
          <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="var(--violet3)">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg> <small className="link">Back to works</small>
        </Link>
        
        <h2>{post.data.title.text}</h2>
        
        <div className="flexH" style={{justifyContent: "center"}}>
          {
            categoriesPost.map((category, index) =>{
            return <p key={index} className="tag">{category}</p>
            })
          }
        </div>

        <GatsbyImage image={image} alt={post.data.title.text} />
        

        <SliceZone sliceZone={post.data.body}/>

        <Pagination pageUid={post.uid} pageIndex={data.allPrismicPost}/>
         
      </Layout>
    </React.Fragment>
  )
}

export default Post

export const pageQuery = graphql`
  query postBySlugAndMyQueryAndMyQuery($uid: String) {
    allPrismicPost {
      pageInfo {
        pageCount
        currentPage
      }
      edges {
        node {
          uid
          data {
            title {
              text
            }
          }
        }
      }
    } 
    prismicPost(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        date
        cover {
          gatsbyImageData
        }
        body {
          ... on PrismicPostDataBodyEmbed {
            slice_type
            primary {
              embed_link {
                html
              }
            }
          }
          ... on PrismicPostDataBodySeparator {
            slice_type
          }
          ... on PrismicPostDataBodyQuote {
            slice_type
            primary {
              quote_image {
                gatsbyImageData
              }
              quote_source {
                text
              }
              quote_text {
                text
              }
              quote_title {
                text
              }
            }
          }
          ... on PrismicPostDataBodyProjectDetails {
            slice_type
            primary {
              big_column_text {
                raw
              }
              h3_title {
                text
              }
              small_column {
                raw
              }
            }
          }
          ... on PrismicPostDataBodyBlockText {
            slice_type
            items {
              background_block
              big_text {
                text
              }
              content_image {
                gatsbyImageData
              }
              content_text {
                raw
              }
              section_h3_title {
                text
              }
            }
          }
          ... on PrismicPostDataBodyImagesSlider {
            slice_type
            primary {
              description {
                raw
              }
              only_two_columns
              title {
                text
              }
            }
            items {
              image {
                gatsbyImageData
              }
              description {
                raw
              }
            }
          }
          ... on PrismicPostDataBodyTwoColumnsBlock {
            slice_type
            items {
              image_first
              block_text {
                raw
              }
              image_block {
                gatsbyImageData
              }
            }
          }
        }
        categories {
          category {
            document {
              ... on PrismicCategory {
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
