import React from 'react'
// styles
import "../styles/slices.css";

import { RichText } from 'prismic-reactjs'

export const ProjectDetails = ({ slice }) => 
    
    <div className="projectDetails containerSlice">
        <h3>{slice.primary.h3_title.text}</h3>
        <div className="pd_textBlock">
            <div className="pd_bigColumn">
               <RichText render={slice.primary.big_column_text.raw}/>   
            </div>
            <div className="pd_smallColumn">
               <RichText render={slice.primary.small_column.raw}/>   
            </div>
            
            
        </div>
        
    </div> 
    