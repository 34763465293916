import React from 'react'
// styles
import "../styles/slices.css";

import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const Quote = ({ slice }) => 
    
    <div className="quoteSlider containerSlice">
        <GatsbyImage
            alt="image"
            image={getImage(slice.primary.quote_image)}
            placeholder="blurred"
        /> 
        <h5>{slice.primary.quote_title.text}</h5>
        <p className="bigViolet2">{slice.primary.quote_text.text}</p>
        <p><small>{slice.primary.quote_source.text}</small></p>
    </div> 
    