import React from 'react'
// styles
import "../styles/slices.css";


import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs'

export const TwoColumns = ({ slice }) => 
    
    slice.items.map((section, index) =>{
        return (
            <div key={index} className={section.image_first ? "tc_Block tc_imgReverse": "tc_Block"}>
                <div className="tc_image">
                   <GatsbyImage
                    alt="image"
                    image={getImage(section.image_block)}
                    placeholder="blurred"
                    />  
                </div>
                <div className="tc_text">
                   <RichText render={section.block_text.raw}/> 
                </div>
                
            </div> 
        )
    })