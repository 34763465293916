// Truncated Pagination.js file

import * as React from 'react'
import { Link } from 'gatsby'
import { pagination, enabled, disabled } from './pagination.module.css'

// ...

export const Pagination = ({ pageUid, pageIndex }) => {

    
  if (!pageUid) return null
  console.log(pageIndex)
  console.log(pageUid)

  const posts = pageIndex.edges
  const currentPage = []
  function getIndex(x) {
        const slug = posts[x].node.uid 
        if ( slug === pageUid) {
            currentPage.push(x);
            console.log(currentPage)
        }
    }
  
  for (let index = 0; index < posts.length; index++) {
    getIndex(index);
      
  }

  

  // Create URL path for previous and next buttons
  const prevPagePath = currentPage[0] === 0 ? `/work/${pageUid}` : '/work/' + (posts[currentPage[0]-1].node.uid ).toString()
  const prevPageName = currentPage[0] === 0 ? `${posts[currentPage[0]].node.data.title.text}` : (posts[currentPage[0]-1].node.data.title.text).toString()
  const nextPagePath = currentPage[0] === posts.length - 1 ? `/work/${pageUid}` :  `/work/${posts[currentPage[0]+1].node.uid}`
  const nextPageName = currentPage[0] === posts.length - 1 ? `${posts[currentPage[0]].node.data.title.text}` : (posts[currentPage[0]+1].node.data.title.text).toString()
  // Check if page is first or last to disable previous and next buttons
  const prevClass = currentPage[0] === 0 ? disabled : enabled
  const nextClass = currentPage[0] === posts.length - 1 ? disabled : enabled

  return (
    <div className={pagination}>
        
        <div className={prevClass}>
          <small>Previous Project</small>
          <Link to={prevPagePath} rel="prev" >
              <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 17l-5-5m0 0l5-5m-5 5h12" />
              </svg>
              <small className="link">{prevPageName}</small>
          </Link> 
        
        </div>
        
        <div className={nextClass} >
          <small>Next Project</small>
          <Link to={nextPagePath} rel="next" >
            <span className="link">{nextPageName}</span>
            <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg> 
          </Link> 
        </div>
     
      
    </div>
  )
}

