import React from 'react'
// styles
import "../styles/slices.css";


import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs'

export const ImageSlider = ({ slice }) => 
    <div className="is_section">
        <h3>{slice.primary.title.text}</h3>
        <RichText render={slice.primary.description.raw}/> 
        <div className="is_blockContent">
            {slice.items.map((section, index) =>{
            return (
                <div key={index} className={slice.primary.only_two_columns ? "is_TwoColumnBlock": "is_ColumnBlock"}>
                    <div className="is_image">
                    <GatsbyImage
                        alt="image"
                        image={getImage(section.image)}
                        placeholder="blurred"
                        />  
                    </div>
                    <div className="is_text">
                    <RichText render={section.description.raw}/> 
                    </div>
                    
                </div> 
            )
            })}
        </div> 
        
       
    </div>
    