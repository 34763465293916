import React from 'react'
// styles
import "../styles/slices.css";

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs'

export const BlockText = ({ slice }) => 
    
    slice.items.map((section, index) =>{
    return (
        <div key={index} className={section.background_block ? "btBlock btBlockFocus": "btBlock"}>
            <h3>{section.section_h3_title.text}</h3>
            <p className="bigText">{section.big_text.text}</p>
            <RichText render={section.content_text.raw}/>
            <GatsbyImage
                alt="image"
                image={getImage(section.content_image)}
                placeholder="blurred"
            /> 
            
        </div> 
    )
    
    })
  