
import * as React from 'react'
import { Separator } from '../slices/Separator'
import { Embed } from '../slices/Embed'
import { Quote } from '../slices/Quote'
import { ProjectDetails } from '../slices/ProjectDetails'
import { BlockText } from '../slices/BlockText'
import { TwoColumns } from '../slices/TwoColumns'
import { ImageSlider } from '../slices/ImageSlider'

export const SliceZone = ({ sliceZone }) => {
    const sliceComponents = {
      separator: Separator,
      embed: Embed,
      quote: Quote,
      project_details: ProjectDetails,
      block_text: BlockText,
      two_columns_block: TwoColumns,
      images_slider: ImageSlider,
    }
  
    const sliceZoneContent = sliceZone.map((slice, index) => {
      const SliceComponent = sliceComponents[slice.slice_type]
      if (SliceComponent) {
        return <SliceComponent slice={slice} key={`slice-${index}`} />
      }
      return null
    })
  
    return <main className="container">{sliceZoneContent}</main>
  }